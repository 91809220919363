import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export const FadeInUp = memo(function FadeInUp({
  children,
  delay,
  className,
  threshold,
}: any) {
  let fadeThreshold = threshold || 0.4
  const [ref, isVisible] = useInView({
    threshold: fadeThreshold,
    triggerOnce: true,
  })

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay || 0,
        bounce: 0,
        duration: 0.8,
        type: 'spring',
      },
    },
    hidden: {
      opacity: 0,
      y: 40,
    },
  }

  return (
    <motion.div
      ref={ref}
      className={className}
      animate={isVisible ? 'visible' : 'hidden'}
      variants={variants}
      initial={variants.hidden}
    >
      {children}
    </motion.div>
  )
})
