import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  className?: string
  maxWidth?: number
  text: string
}

export const AnimatedTitle = memo(function AnimatedTitle({
  className,
  maxWidth,
  text,
}: Props) {
  const [ref, isVisible] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const variants = {
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.1,
        ease: [0.65, 0.2, 0.3, 0.99],
        //type: 'spring',
        duration: 0.6,
      },
    }),
    hidden: {
      opacity: 0,
      y: 60,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
  }

  const wrapperRef = useRef(null)
  const [lines, setLines] = useState([])
  const splitedWords = text.split(' ')

  let line = [] as any
  let testLine = [] as any
  let output = [] as any

  function makeLines() {
    const wrapper = wrapperRef.current as any

    splitedWords.forEach((word) => {
      testLine.push(word)
      wrapper.innerHTML = testLine.join(' ')

      if (wrapper.scrollWidth > wrapper.clientWidth) {
        output.push(line.join(' '))
        testLine = []
        line = []
      }
      let wordWithBreak = word.split('<br>')
      if (wordWithBreak.length > 1) {
        line.push(wordWithBreak[0])
        output.push(line.join(' '))
        testLine = [wordWithBreak[1]]
        line = [wordWithBreak[1]]
      } else {
        line.push(word)
      }
    })

    if (line.length > 0) {
      output.push(line.join(' '))
    }
  }

  useEffect(() => {
    makeLines()
    setLines(output)
  }, [])

  return (
    <Container
      className={className}
      style={maxWidth ? { maxWidth: `${maxWidth}px` } : undefined}
    >
      <Ghost ref={wrapperRef} />

      {lines.map((line, index) => (
        <Wrapper key={line + index} ref={ref}>
          <Line
            animate={isVisible ? 'visible' : 'hidden'}
            ref={ref}
            variants={variants}
            custom={index}
            initial={variants.hidden}
          >
            {line}
          </Line>
        </Wrapper>
      ))}
    </Container>
  )
})

const Container = styled.h2``

const Ghost = styled.div`
  display: hidden;
  height: 0;
  overflow-x: scroll;
  white-space: nowrap;
`

const Wrapper = styled.div`
  overflow: hidden;
`

const Line = styled(motion.div)``
